import { useEffect } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

function App() {
  useEffect(() => {
    const map = new maplibregl.Map({
      container: "map",
      style: `https://tileria.nextbillion.io/maps/v1/style.json`,
      zoom: 9,
      center: { lat: -6.36088, lng: 106.85256 },
      hash: true,
      maxZoom: 21.9,
      transformRequest,
    });
    map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        showUserHeading: true,
        showAccuracyCircle: true,
        trackUserLocation: false,
      })
    );
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: "0",
        left: "0",
      }}
      id="map"
    />
  );
}

function transformRequest(url, resourceType) {
  const key = "c706cb645a1f491382dd40dd5ad4f122";
  if (
    (resourceType === "SpriteJSON" ||
      resourceType === "SpriteImage" ||
      resourceType === "Tile" ||
      resourceType === "Source" ||
      resourceType === "Glyphs" ||
      resourceType === "Style") &&
    key
  ) {
    return {
      url: urlAddOrReplaceParam(url, { key: key }),
    };
  }
  return { url };
}

function urlAddOrReplaceParam(baseUrl, params) {
  const url = new URL(baseUrl);
  for (const key in params) {
    url.searchParams.set(key, params[key]);
  }
  return url.toString();
}

export default App;
